define("goodcity-lib/controllers/search_module", ["exports", "ember", "ember-infinity/mixins/route"], function (exports, _ember, _emberInfinityMixinsRoute) {
  exports["default"] = _ember["default"].Controller.extend(_emberInfinityMixinsRoute["default"], {

    queryParams: ["showQuantityItems"],
    showQuantityItems: false,

    getCurrentUser: _ember["default"].computed(function () {
      var store = this.get('store');
      var currentUser = store.peekAll('user_profile').get('firstObject') || null;
      return currentUser;
    }).volatile(),

    sanitizeString: function sanitizeString(str) {
      // these are the special characters '.,)(@_-' that are allowed for search
      // '\.' => will allow '.'
      // '\(' => will allow '('
      // '\@' => will allow '@'
      // '\)' => will allow ')'
      str = str.replace(/[^a-z0-9áéíóúñü \.,\)\(@_-]/gim, "");
      return str.trim();
    },

    searchText: _ember["default"].computed('searchInput', {
      get: function get() {
        return this.get('searchInput') || "";
      },

      set: function set(key, value) {
        return this.sanitizeString(value);
      }
    }),

    i18n: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),
    isLoading: false,
    hasNoResults: false,
    itemSetId: null,
    unloadAll: false,
    minSearchTextLength: 0,
    searchInput: null,
    toDesignateItem: null,

    hasSearchText: _ember["default"].computed("searchText", function () {
      return !!this.get("searchText");
    }),

    onSearchTextChange: _ember["default"].observer("searchText", function () {
      // wait before applying the filter
      if (this.get("searchText").length > this.get("minSearchTextLength")) {
        this.set("itemSetId", null);
        _ember["default"].run.debounce(this, this.applyFilter, 500);
      } else {
        this.set("filteredResults", []);
      }
    }),

    applyFilter: function applyFilter() {
      var _this = this;

      var searchText = this.get("searchText");
      if (searchText.length > 0) {
        this.set("isLoading", true);
        this.set("hasNoResults", false);
        if (this.get("unloadAll")) {
          this.get("store").unloadAll();
        }

        this.infinityModel(this.get("searchModelName"), { perPage: 25, startingPage: 1, modelPath: 'filteredResults', stockRequest: true }, { searchText: "searchText", itemId: "itemSetId", toDesignateItem: "toDesignateItem", showQuantityItems: "showQuantityItems" }).then(function (data) {
          data.forEach(function (record) {
            if (record.constructor.toString() === "stock@model:designation:") {
              _this.store.query("orders_package", { search_by_order_id: record.get("id") });
            }
          });
          if (_this.get("searchText") === data.meta.search) {
            _this.set("filteredResults", data);
            _this.set("hasNoResults", data.get("length") === 0);
          }
        })["finally"](function () {
          return _this.set("isLoading", false);
        });
      }
      this.set("filteredResults", []);
    },

    afterInfinityModel: function afterInfinityModel(records) {
      var searchText = this.get("searchText");
      if (searchText.length === 0 || searchText !== records.meta.search) {
        records.replaceContent(0, 25, []);
      }
    },

    actions: {
      clearSearch: function clearSearch() {
        this.set("searchText", "");
      }
    }
  });
});