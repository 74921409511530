define("goodcity-lib/controllers/search_organisation", ["exports", "ember", "goodcity-lib/controllers/search_module"], function (exports, _ember, _goodcityLibControllersSearch_module) {
  exports["default"] = _goodcityLibControllersSearch_module["default"].extend({
    minSearchTextLength: 3,

    onSearchTextChange: _ember["default"].observer("searchText", function () {
      if (this.get('searchText').length) {
        _ember["default"].run.debounce(this, this.applyFilter, 500);
      } else {
        this.set("filteredResults", []);
      }
    }),

    applyFilter: function applyFilter() {
      var _this = this;

      var searchText = this.get("searchText");
      if (searchText.length > this.get("minSearchTextLength")) {
        this.set("isLoading", true);
        this.set("hasNoResults", false);
        if (this.get("unloadAll")) {
          this.get("store").unloadAll();
        }

        this.infinityModel("gcOrganisation", { startingPage: 1, perPage: 25, modelPath: 'filteredResults' }, { searchText: "searchText" }).then(function (data) {
          if (_this.get("searchText") === data.meta.search) {
            _this.set("filteredResults", data);
            _this.store.pushPayload(data);
            _this.set("hasNoResults", data.get("length") === 0);
          }
        })["finally"](function () {
          return _this.set("isLoading", false);
        });
      }
      this.set("filteredResults", []);
    },

    actions: {
      cancelSearch: function cancelSearch() {
        _ember["default"].$("#searchText").blur();
        this.send("clearSearch", true);
        this.transitionToRoute("app_menu_list");
      },

      selectOrganisation: function selectOrganisation(organisation) {
        this.transitionToRoute('account_details', { queryParams: { orgId: organisation.id } });
      }
    }
  });
});