define("goodcity-lib/components/online-button", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend(_ember["default"].TargetActionSupport, {
    disabled: false,
    updateDisabled: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.updateDisabled = _ember["default"].run.bind(this, function () {
        var online = navigator.connection ? navigator.connection.type !== "none" : navigator.onLine;
        _this.set("disabled", !online);
      });
      this.updateDisabled();
      window.addEventListener("online", this.updateDisabled);
      window.addEventListener("offline", this.updateDisabled);
    },

    willDestroyElement: function willDestroyElement() {
      if (this.updateDisabled) {
        window.removeEventListener("online", this.updateDisabled);
        window.removeEventListener("offline", this.updateDisabled);
      }
    },

    click: function click() {
      if (this.get('disabled')) {
        return false;
      }

      if (_ember["default"].$('.message-bar')[0].value === '') {
        _ember["default"].$('.message-bar').parent().addClass('has-error');
        return false;
      } else {
        this.get('onClick')();
      }
    }
  });
});