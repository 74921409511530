define('goodcity-lib/components/select-list', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    content: null,
    selectedValue: null,

    actions: {
      change: function change() {
        var changeAction = this.get('on-change');
        var selectedIndex = this.$('select').prop('selectedIndex');
        var content = this.get('content').toArray();
        if (this.get("prompt")) {
          content = [{ name: null }].concat(content);
        }
        var selectedValue = content[selectedIndex];

        this.set('selectedValue', selectedValue);
        changeAction(selectedValue);
      }
    }
  });
});