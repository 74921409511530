define('ember-offline/services/offline', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var on = _ember['default'].on;
  var equal = _ember['default'].computed.equal;
  exports['default'] = _ember['default'].Service.extend({
    state: 'up',
    isUp: equal('state', 'up'),
    isDown: equal('state', 'down'),

    check: function check() {
      return get(this, 'offline').check();
    },

    _setupEvents: on('init', function () {
      var _this = this;

      var offline = get(this, 'offline');

      offline.on('up', function () {
        set(_this, 'state', 'up');
      });

      offline.on('down', function () {
        set(_this, 'state', 'down');
      });
    })
  });
});