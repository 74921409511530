define('goodcity-lib/computed/local-storage', ['exports', 'ember'], function (exports, _ember) {

  var storageSupported = false;
  try {
    localStorage.test = 2;delete localStorage.test;storageSupported = true;
  } catch (err) {
    console.log(err);
  }

  var cookiesSupported = false;
  try {
    _ember['default'].$.cookie('test', 2);_ember['default'].$.removeCookie('test');cookiesSupported = true;
  } catch (err) {
    console.log(err);
  }

  var localStorageProvider = {
    get: function get(key) {
      try {
        return JSON.parse(localStorage[key] || null);
      } catch (e) {
        return null;
      }
    },
    set: function set(key, value) {
      if (_ember['default'].isNone(value)) {
        delete localStorage[key];
      } else {
        localStorage[key] = JSON.stringify(value);
      }
      return value;
    }
  };

  var cookieStorageProvider = {
    get: function get(key) {
      return _ember['default'].$.cookie(key);
    },
    set: function set(key, value) {
      _ember['default'].$.cookie.json = true;
      if (_ember['default'].isNone(value)) {
        _ember['default'].$.removeCookie(key);
      } else {
        var config = _ember['default'].getOwner(this).lookup('config:environment');
        _ember['default'].$.cookie(key, value, { expires: 365, path: '/', secure: config.environment === 'production' });
      }
      return value;
    }
  };

  var memoryStorageProvider = {
    get: function get(key) {
      if (!window.goodcityStorage) {
        window.goodcityStorage = {};
      }
      return window.goodcityStorage[key];
    },
    set: function set(key, value) {
      if (!window.goodcityStorage) {
        window.goodcityStorage = {};
      }
      if (_ember['default'].isNone(value)) {
        delete window.goodcityStorage[key];
      } else {
        window.goodcityStorage[key] = value;
      }
      return value;
    }
  };

  exports['default'] = _ember['default'].computed.localStorage = function () {
    if (storageSupported) {
      return _ember['default'].computed(localStorageProvider);
    }

    if (cookiesSupported) {
      return _ember['default'].computed(cookieStorageProvider);
    }

    return _ember['default'].computed(memoryStorageProvider);
  };
});