define("ember-collection/components/ember-collection", ["exports", "ember-collection/components/ember-collection/template", "ember-collection/utils/identity", "ember-collection/utils/needs-revalidate"], function (_exports, _template, _identity, _needsRevalidate2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  var Cell = function Cell(key, item, index, style) {
    _classCallCheck(this, Cell);

    this.key = key;
    this.hidden = false;
    this.item = item;
    this.index = index;
    this.style = style;
  };

  function noop() {}

  var _default = Ember.Component.extend({
    layout: _template.default,
    init: function init() {
      // State pulled from attrs is prefixed with an underscore
      // so that there's no chance of shadowing the attrs proxy.
      this._buffer = undefined;
      this._cellLayout = undefined;
      this._rawItems = undefined;
      this._items = undefined;
      this._scrollLeft = undefined;
      this._scrollTop = undefined;
      this._clientWidth = undefined;
      this._clientHeight = undefined;
      this._contentSize = undefined; // this.firstCell = undefined;
      // this.lastCell = undefined;
      // this.cellCount = undefined;

      this.contentElement = undefined;
      this._cells = Ember.A();
      this._cellMap = Object.create(null); // TODO: Super calls should always be at the top of the constructor.
      // I had to move the super call after the properties were defined to
      // work around what I believe is a bug in the attrs proxy. The problem
      // seems to arise when you:
      //
      //   1. Call this._super() immediately.
      //   2. Set a property on `this` that is both not in the
      //      initial attrs hash and not on the prototype.

      this._super(); // initialize from passed in attrs


      var buffer = this.getAttr('buffer'); // getIntAttr('buffer', 5)

      this._buffer = typeof buffer === 'number' ? buffer : 5;
      this._scrollLeft = this.getAttr('scroll-left') | 0;
      this._scrollTop = this.getAttr('scroll-top') | 0;
      this._clientWidth = this.getAttr('estimated-width') | 0;
      this._clientHeight = this.getAttr('estimated-height') | 0;
      this._scrollChange = this.getAttr('scroll-change');
    },
    _needsRevalidate: function _needsRevalidate() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      if (this._isGlimmer2()) {
        this.rerender();
      } else {
        (0, _needsRevalidate2.default)(this);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      // Work around emberjs/ember.js#11992. Affects <=1.13.8 and <=2.0.0.
      // This will likely be patched in 1.13.9 and 2.0.1.
      this._super();

      this.updateItems();
      this.updateScrollPosition();
    },
    willDestroyElement: function willDestroyElement() {
      if (this._items && this._items.removeArrayObserver) {
        this._items.removeArrayObserver(this, {
          willChange: noop,
          didChange: '_needsRevalidate'
        });
      }
    },
    updateItems: function updateItems() {
      this._cellLayout = this.getAttr('cell-layout');
      var rawItems = this.getAttr('items');

      if (this._rawItems !== rawItems) {
        if (this._items && this._items.removeArrayObserver) {
          this._items.removeArrayObserver(this, {
            willChange: noop,
            didChange: '_needsRevalidate'
          });
        }

        this._rawItems = rawItems;
        var items = Ember.A(rawItems);
        this.set('_items', items);

        if (items && items.addArrayObserver) {
          items.addArrayObserver(this, {
            willChange: noop,
            didChange: '_needsRevalidate'
          });
        }
      }
    },
    updateScrollPosition: function updateScrollPosition() {
      if (!this._scrollChange) {
        return;
      } // don't process bound scroll coords unless our action is being handled


      var scrollLeftAttr = this.getAttr('scroll-left');

      if (scrollLeftAttr !== undefined) {
        scrollLeftAttr = parseInt(scrollLeftAttr, 10);

        if (this._scrollLeft !== scrollLeftAttr) {
          this.set('_scrollLeft', scrollLeftAttr);
        }
      }

      var scrollTopAttr = this.getAttr('scroll-top');

      if (scrollTopAttr !== undefined) {
        scrollTopAttr = parseInt(scrollTopAttr, 10);

        if (this._scrollTop !== scrollTopAttr) {
          // console.log('updateScrollPosition', this._scrollTop, scrollTopAttr);
          this.set('_scrollTop', scrollTopAttr);
        }
      }
    },
    updateContentSize: function updateContentSize() {
      var cellLayout = this._cellLayout;
      var contentSize = cellLayout.contentSize(this._clientWidth, this._clientHeight);

      if (this._contentSize === undefined || contentSize.width !== this._contentSize.width || contentSize.height !== this._contentSize.height) {
        this.set('_contentSize', contentSize);
      }
    },
    willRender: function willRender() {
      this.updateCells();
      this.updateContentSize();
    },
    updateCells: function updateCells() {
      if (!this._items) {
        return;
      }

      var numItems = Ember.get(this._items, 'length');

      if (this._cellLayout.length !== numItems) {
        this._cellLayout.length = numItems;
      }

      var priorMap = this._cellMap;
      var cellMap = Object.create(null);

      var index = this._cellLayout.indexAt(this._scrollLeft, this._scrollTop, this._clientWidth, this._clientHeight);

      var count = this._cellLayout.count(this._scrollLeft, this._scrollTop, this._clientWidth, this._clientHeight);

      var items = this._items;
      var bufferBefore = Math.min(index, this._buffer);
      index -= bufferBefore;
      count += bufferBefore;
      count = Math.min(count + this._buffer, Ember.get(items, 'length') - index);
      var i, style, itemIndex, itemKey, cell;
      var newItems = [];

      for (i = 0; i < count; i++) {
        itemIndex = index + i;
        itemKey = (0, _identity.default)(items.objectAt(itemIndex));

        if (priorMap) {
          cell = priorMap[itemKey];
        }

        if (cell) {
          style = this._cellLayout.formatItemStyle(itemIndex, this._clientWidth, this._clientHeight);
          Ember.set(cell, 'style', style);
          Ember.set(cell, 'hidden', false);
          Ember.set(cell, 'key', itemKey);
          Ember.set(cell, 'index', itemIndex);
          cellMap[itemKey] = cell;
        } else {
          newItems.push(itemIndex);
        }
      }

      for (i = 0; i < this._cells.length; i++) {
        cell = this._cells[i];

        if (!cellMap[cell.key]) {
          if (newItems.length) {
            itemIndex = newItems.pop();
            var item = items.objectAt(itemIndex);
            itemKey = (0, _identity.default)(item);
            style = this._cellLayout.formatItemStyle(itemIndex, this._clientWidth, this._clientHeight);
            Ember.set(cell, 'style', style);
            Ember.set(cell, 'key', itemKey);
            Ember.set(cell, 'index', itemIndex);
            Ember.set(cell, 'item', item);
            Ember.set(cell, 'hidden', false);
            cellMap[itemKey] = cell;
          } else {
            Ember.set(cell, 'hidden', true);
            Ember.set(cell, 'style', 'height: 0; display: none;');
          }
        }
      }

      for (i = 0; i < newItems.length; i++) {
        itemIndex = newItems[i];

        var _item = items.objectAt(itemIndex);

        itemKey = (0, _identity.default)(_item);
        style = this._cellLayout.formatItemStyle(itemIndex, this._clientWidth, this._clientHeight);
        cell = new Cell(itemKey, _item, itemIndex, style);
        cellMap[itemKey] = cell;

        this._cells.pushObject(cell);
      }

      this._cellMap = cellMap;
    },
    _isGlimmer2: function _isGlimmer2() {
      return !this._renderNode;
    },
    actions: {
      scrollChange: function scrollChange(scrollLeft, scrollTop) {
        if (this._scrollChange) {
          this._scrollChange(scrollLeft, scrollTop);
        } else {
          if (scrollLeft !== this._scrollLeft || scrollTop !== this._scrollTop) {
            Ember.set(this, '_scrollLeft', scrollLeft);
            Ember.set(this, '_scrollTop', scrollTop);

            this._needsRevalidate();
          }
        }
      },
      clientSizeChange: function clientSizeChange(clientWidth, clientHeight) {
        if (this._clientWidth !== clientWidth || this._clientHeight !== clientHeight) {
          Ember.set(this, '_clientWidth', clientWidth);
          Ember.set(this, '_clientHeight', clientHeight);

          this._needsRevalidate();
        }
      }
    }
  });

  _exports.default = _default;
});