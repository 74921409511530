define('goodcity-lib/components/message-box', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    layoutName: 'components/message-box',
    message: "",
    btn1Text: "",
    btn1Callback: function btn1Callback() {},
    btn2Text: "",
    btn2Callback: function btn2Callback() {},
    displayCloseLink: false,

    isVisible: false,

    close: function close() {
      if (this.get("isVisible")) {
        this.set("isVisible", false);
      } else {
        this.destroy();
      }
    },

    actions: {
      btn1Click: function btn1Click() {
        var callbackOutput = true;
        if (this.btn1Callback) {
          callbackOutput = this.btn1Callback();
        }
        if (callbackOutput !== false) {
          this.close();
        }
      },

      btn2Click: function btn2Click() {
        if (this.btn2Callback) {
          this.btn2Callback();
        }
        this.close();
      },

      closeModal: function closeModal() {
        this.close();
      }
    }
  });
});